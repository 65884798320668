import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
export { wrapRootElement } from "./src/apollo/wrap-root-element";

CheckWebpFeature();

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
  
    // Avoid to scroll to top when next page trigger click
    // But this feature should condiser with details page back scroll too
    if (location.pathname.match(/property\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/to-rent/)) {
      return false;
    }
    
    if (location.pathname.match(/property\/new-homes\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/new-homes\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property\/new-developments\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/new-developments\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property\/developments\/new-homes\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property\/developments\/new-homes\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property-map\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property-map\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property-map\/new-homes\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property-map\/new-homes\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property-map\/new-developments\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property-map\/new-developments\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property-map\/developments\/new-homes\/for-sale/)) {
      return false;
    }
    if (location.pathname.match(/property-map\/developments\/new-homes\/to-rent/)) {
      return false;
    }

    if (location.pathname.match(/property\/help-to-buy/)) {
      return false;
    }
    if (location.pathname.match(/property-map\/help-to-buy/)) {
      return false;
    }
  
    return true;
};